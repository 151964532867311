import React, { useState, useEffect } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar, Breadcrumbs, Typography, IconButton, Badge, Grid } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HomeIcon from "@mui/icons-material/Home";
import menuItems from "./menuItens";

interface MenuItem {
  title: string;
  breadcrumb: string;
  path?: string;
  icon: JSX.Element;
  subNav?: MenuItem[];
}

const Header = () => {
  const [title, setTitle] = useState("Título");
  const [icon, setIcon] = useState<JSX.Element | null>(null);
  const location = useLocation();

  const findRouteByPath = (path: string, routes: MenuItem[]): MenuItem | null => {
    for (const route of routes) {
      if (route.path === path) {
        return route;
      }
      if (route.subNav) {
        const subRoute: MenuItem | null = findRouteByPath(path, route.subNav);
        if (subRoute) {
          return subRoute;
        }
      }
    }
    return null;
  };

  // Ajustado para usar o path da rota para os links do breadcrumb
  const getBreadcrumbPaths = (pathname: string) => {
    let pathParts = pathname.split("/").filter((x) => x);
    let pathAccum = "";
    return pathParts.map((part) => {
      pathAccum += `/${part}`;
      return pathAccum;
    });
  };

  const breadcrumbPaths = getBreadcrumbPaths(location.pathname);

  useEffect(() => {
    const route = findRouteByPath(location.pathname, menuItems);
    if (route) {
      setTitle(route.title);
      setIcon(route.icon);
    }
  }, [location.pathname]);

  return (
    <AppBar position="static" sx={{ height: 205, padding: "0 1rem" }}>
      <Toolbar
        sx={{
          height: 205,
          justifyContent: "space-between",
          alignItems: "flex-end", // Isso alinha os itens à parte inferior do Toolbar
          paddingBottom: "1rem",
        }}
      >
        <Grid container spacing={2} alignItems="flex-end">
          {" "}
          <Grid item xs={12} container alignItems="flex-end">
            {" "}
            {icon && (
              <React.Fragment>
                {React.cloneElement(icon, { sx: { fontSize: 32 } })}
                <Typography variant="h6" color="inherit" sx={{ marginLeft: 2 }}>
                  {title}
                </Typography>
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            {" "}
            {/* Isso alinha os breadcrumbs ao fundo */}
            <Breadcrumbs aria-label="breadcrumb" sx={{ color: "white" }}>
              <HomeIcon sx={{ fontSize: 20 }} />
              {breadcrumbPaths.map((path, index) => {
                const route = findRouteByPath(path, menuItems);
                const breadcrumbText = route ? route.breadcrumb : path.substring(path.lastIndexOf("/") + 1);
                return (
                  <RouterLink key={index} to={path} style={{ color: "inherit", textDecoration: "none" }}>
                    {breadcrumbText}
                  </RouterLink>
                );
              })}
            </Breadcrumbs>
          </Grid>
        </Grid>
        <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
