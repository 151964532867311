//index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('888f5b051b3ea0ff53d97b99ba153785Tz04NDEzNSxFPTE3Mzk0MDc4MDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
