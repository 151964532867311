import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Layout from './Layout';

interface PrivateRouteProps {
  children: ReactNode; // Definindo o tipo de `children` como ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Layout>{children}</Layout> : <Navigate to="/" />;
};

export default PrivateRoute;
