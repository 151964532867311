import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Login from './Login';
import LoadingScreen from './LoadingScreen';


const Dashboard = lazy(() => import('./Dashboard'));
const UsersList = lazy(() => import('./UsersList'));
const InvoicesList = lazy(() => import('./pages/emails/InvoicesList'));
const PessoasList = lazy(() => import('./pages/pessoas/pessoasList'));

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<LoadingScreen progress={100}/>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/emails" element={<PrivateRoute><InvoicesList /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><UsersList /></PrivateRoute>} />
          <Route path="/pessoas" element={<PrivateRoute><PessoasList /></PrivateRoute>} />
        </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
};

export default App;
