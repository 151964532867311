import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import EmailIcon from '@mui/icons-material/Email';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';


const menuItems = [
  {
    title: "Dashboard",
    breadcrumb: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    title: "E-mails",
    breadcrumb: "E-mails",
    path: "/emails",
    icon: <EmailIcon />,
  },
  {
    title: "Usuários",
    breadcrumb: "Usuários",
    path: "/users",
    icon: <PeopleIcon />,
  },
  {
    title: "Pessoas",
    breadcrumb: "Pessoas",
    path: "/pessoas",
    icon: <PeopleIcon />,
  },
  /*{
    title: "Contas",
    breadcrumb: "Contas",
    icon: <AccountBalanceWalletIcon />,
    subNav: [
      {
        title: "Saldos & Extratos",
        breadcrumb: "Saldos & Extratos",
        path: "/banking/saldos",
        icon: <GroupIcon />,
      },
      {
        title: "Cobranças",
        breadcrumb: "Cobranças",
        path: "/banking/invoices",
        icon: <GroupIcon />,
      },
    ],
  },*/
];

export default menuItems;