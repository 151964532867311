import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemIcon, ListItemText, Collapse, Avatar, Divider, ListItemButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import RadioUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import RadioChecked from "@mui/icons-material/RadioButtonChecked";
import { grey } from "@mui/material/colors";
import menuItems from "./menuItens";
import { useAuth } from "./AuthContext";

const Menu = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { logout } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    logout();
  };

  const handleClick = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh" width="240px">
      <List component="nav">
        <ListItem>
          <Avatar sx={{ width: 160, height: 160, margin: "10px auto" }} src="https://r2.starryai.com/results/1007241237/e011e1e1-6bff-4e3d-981b-518d3b730646.webp" alt="Avatar" />
        </ListItem>
        <Divider />
        {menuItems.map((item : any, index: number) => (
          <div key={index}>
            <ListItem button component={item.path ? RouterLink : "div"} to={item.path} onClick={() => handleClick(index)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
              {item.subNav ? openIndex === index ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
            {item.subNav && (
              <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ bgcolor: grey[200] }}>
                  {item.subNav.map((subItem : any, subIndex : number) => (
                    <ListItem
                      button
                      key={subIndex}
                      component={RouterLink}
                      to={subItem.path}
                      sx={{
                        bgcolor: location.pathname === subItem.path ? grey[300] : grey[200],
                        marginLeft: 4,
                      }}
                    >
                      <ListItemIcon>{location.pathname === subItem.path ? <RadioChecked /> : <RadioUnchecked />}</ListItemIcon>
                      <ListItemText primary={subItem.title} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
      <Box flexGrow={1} />
      <List>
        <Divider />
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Box>
  );
};

export default Menu;
