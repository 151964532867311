import React, { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Header from './Header';
import Menu from './Menu';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%', overflow: 'hidden' }}>
      {/* Menu Toggle Button */}
      <IconButton
        onClick={handleMenuToggle}
        sx={{ 
          position: 'fixed', 
          zIndex: 1300, // Garanta que esteja acima de outros elementos
          top: 8, 
          left: isMenuOpen ? 240 : 8, // Ajuste conforme a largura do seu Menu
          color: 'primary.contrastText', // Adapte conforme o seu tema
        }}
      >
        {isMenuOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>

      {/* Menu Lateral */}
      <Box
        component="nav"
        sx={{
          width: isMenuOpen ? 240 : 0, // Ajuste conforme a largura do seu Menu
          flexShrink: { sm: 0 },
          display: { xs: 'none', sm: 'block' }, // Exemplo de responsividade
          height: '100vh', // Garante que o menu ocupe a altura total
          overflowY: 'auto' // Permite rolagem no menu se necessário
        }}
      >
        {isMenuOpen && <Menu />}
      </Box>

      {/* Conteúdo Principal incluindo Header */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto',backgroundColor: "grey.200", p: 3}}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
